import { css } from 'styled-components';

import COLORS from './colors';
import { generate, Direction } from './dotted-line';
import FONTS, { base } from './fonts';

const em = (px: number) => `${px / base}em`;

const markdown = css`
  & *:first-child {
    margin-top: 0;
  }
  & *:last-child {
    margin-bottom: 0;
  }

  a {
    color: ${COLORS.brand.regular.css};
    text-decoration: none;
    cursor: pointer;
  }

  a:hover {
    text-decoration: underline;
  }

  p,
  blockquote,
  dl,
  table,
  pre {
    margin: ${em(20)} 0 0;
    white-space: pre-wrap;
  }

  p,
  blockquote,
  dl,
  table,
  pre,
  li {
    ${FONTS.aktiv.regular}
    font-size: ${em(16)};
    line-height: 146%;
  }

  ul,
  ol {
    padding: 0;
    margin: ${em(20)} 0 0;
    padding-left: ${em(16)};
    display: flex;
    flex-direction: column;
    gap: ${em(8)};
    white-space: wrap;
  }

  li {
    margin: 0;
    & p:first-child,
    & div:first-child {
      margin: 0;
      display: inline;
    }
  }

  ul > li {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: ${em(9)};
  }
  ol > li {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: ${em(9)};
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: ${em(7)};
  }
  ul ul > li {
    list-style-type: circle;
    list-style-position: inside;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: ${em(15)};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${FONTS.aktiv.medium};
    line-height: 128%;
    letter-spacing: -0.015em;
    & * {
      font-weight: 500;
    }
  }

  h1 {
    font-size: ${em(32)};
    margin-top: ${em(24)};
    margin-bottom: ${em(6)};
  }

  h2 {
    font-size: ${em(28)};
    margin-top: ${em(24)};
    margin-bottom: ${em(6)};
  }

  h3 {
    font-size: ${em(24)};
    margin-top: ${em(21)};
    margin-bottom: ${em(6)};
  }

  h4 {
    font-size: ${em(18)};
    margin-top: ${em(21)};
    margin-bottom: ${em(6)};
  }

  h5 {
    font-size: ${em(16)};
    margin-top: ${em(21)};
    margin-bottom: ${em(6)};
  }

  h6 {
    font-size: ${em(14)};
    margin-top: ${em(21)};
    margin-bottom: ${em(3)};
  }

  blockquote {
    display: block;
    background-color: ${COLORS.shades.s100.css};
    border-radius: 3px;
    border: 1px solid #ddd;
    border-left-width: 6px;
    font-size: ${em(15)};
    margin: ${em(20)} 0 0;
    padding: 8px 12px 8px 24px;
    white-space: pre;
    overflow-x: scroll;
    position: relative;

    display: flex;
    flex-direction: column;

    & blockquote {
      border-top: none;
      border-bottom: none;
      border-right: none;
      border-right: 0;
      overflow-x: auto;
    }
  }

  code {
    background-color: #f8f8f8;
    border-radius: 3px;
    border: 1px solid #ddd;
    font-family: Consolas, 'Liberation Mono', Courier, monospace;
    font-size: ${em(12)};
    margin: 0 2px;
    padding: 0 5px;
    white-space: pre;
  }

  pre {
    background-color: #f8f8f8;
    border-radius: 3px;
    border: 1px solid #ddd;
    font-family: Consolas, 'Liberation Mono', Courier, monospace;
    font-size: ${em(12)};
    margin: ${em(20)} 0 0;
    padding: 6px 24px;
    overflow-x: scroll;
    & code {
      background: transparent;
      border-radius: 0;
      border: none;
      margin: 0;
      padding: 0;
    }
    & pre {
      background-color: transparent;
      border-radius: 0;
      border: none;
      margin: 0;
      padding: 0;
      overflow-x: scroll;
    }
  }

  hr {
    border: none;
    display: inline-block;
    width: 100%;
    ${generate(COLORS.shades.s500.css, Direction.HORIZONTAL)}
  }
`;

export default markdown;
