import React, { memo, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import type { NavigationLinkFragment } from '../schemas/api';

import Link from './Link';

import { PageContext } from './PageProvider';

import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import { fadeIn } from '../styles/animations';
import { ReactComponent as ChevronDown } from '../assets/icons/chevron-down.svg';
import { Tag } from '../styles/tag';

const SUB_MENU_ITEMS_HEIGHT = 50;

type ContainerProps = {
  $show: boolean;
};
const Container = styled.div<ContainerProps>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background: ${COLORS.black.css};
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: opacity 0.2s ease;
  align-items: center;
  pointer-events: ${(props) => (props.$show ? 'all' : 'none')};

  ${BREAKPOINTS.max.small`
    display: flex;
    `}
`;

const itemStyle = css`
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 18px 12px;
`;

type ItemProps = {
  $i: number;
  $active: boolean;
};
const Item = styled.li<ItemProps>`
  ${TYPO.nav}
  opacity: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation-delay: ${(props) => props.$i * 0.1}s;
  animation-fill-mode: forwards;
  cursor: pointer;
  & > a {
    color: ${COLORS.shades.s200.css};
    ${itemStyle}
  }
`;

type ItemsProps = {
  $show: boolean;
};

const Items = styled.ul<ItemsProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  flex: 1;
  max-height: 500px;

  ${Item} {
    color: ${COLORS.white.css};

    ${(props) =>
      props.$show &&
      css`
        animation-name: ${fadeIn};
        animation-duration: 0.4s;
        animation-timing-function: ease;
      `}
  }
`;

const SubItem = styled.li`
  display: flex;
  list-style: none;
  & > a {
    color: ${COLORS.shades.s200.css};
    ${itemStyle};
    padding: 6px;
  }
  &:last-child a {
    padding-bottom: 0;
  }
`;

type DropdownProps = {
  $expanded: boolean;
  $totalHeight?: number;
};
const Dropdown = styled.ul<DropdownProps>`
  width: 100%;
  height: 100%;
  max-height: ${(props) =>
    props.$expanded ? `${props.$totalHeight}px` : '0px'};
  transition: all 0.2s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  color: ${COLORS.shades.s400.css};
`;

const SubMenu = styled.div<DropdownProps>`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    margin-left: 6px;
    transform: rotate(${(props) => (props.$expanded ? '180deg' : '0deg')});
  }
  ${itemStyle}
`;

type MenuItemProps = {
  link: NavigationLinkFragment;
  index: number;
  active: boolean;
};
const MenuItem = ({ link, index, active }: MenuItemProps) => {
  const [expanded, setExpanded] = useState(false);

  const subMenu = link.items && link.items.items;
  return (
    <Item $i={index} $active={active}>
      {subMenu && subMenu.length > 0 ? (
        <>
          <SubMenu
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
            $expanded={expanded}
          >
            <span>{link.label}</span>
            {link.tag && <Tag>{link.tag}</Tag>}
            <ChevronDown width="12" height="12" />
          </SubMenu>
          <Dropdown
            $expanded={expanded}
            $totalHeight={subMenu.length * SUB_MENU_ITEMS_HEIGHT}
          >
            {subMenu.map((subLink) => {
              return (
                <SubItem key={subLink.label}>
                  <Link link={subLink}>
                    <span>{subLink.label}</span>
                    {subLink.tag && <Tag>{subLink.tag}</Tag>}
                  </Link>
                </SubItem>
              );
            })}
          </Dropdown>
        </>
      ) : (
        <Link link={link} />
      )}
    </Item>
  );
};

const NavigationMobile = () => {
  const { navigation: items, showMobileNav } = useContext(PageContext);
  const { asPath } = useRouter();

  return (
    <Container $show={showMobileNav}>
      <Items $show={showMobileNav}>
        {items.map((link, i) => {
          return (
            <MenuItem
              link={link}
              index={i}
              key={link.label}
              active={asPath === link.url}
            />
          );
        })}
      </Items>
    </Container>
  );
};

export default memo(NavigationMobile);
